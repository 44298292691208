
$tinder-back: #f1c40fff;
$tinder-hate: #e04f5fff;
$tinder-love: #1abc9cff;
$tinder-favorite: #3498dbff;

.__student__group-card-loading__b5fd7 .tinder-hate {
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: $tinder-hate url(/assets/svg/dislike-big.svg) no-repeat fixed center; 
}

.__student__group-card-loading__b5fd7 .tinder-favorite {
  background: $tinder-favorite url(/assets/svg/star-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.__student__group-card-loading__b5fd7 .tinder-love {
  background: $tinder-love url(/assets/svg/like-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.__student__group-card-loading__b5fd7 .tinder-back {
  background: $tinder-back url(/assets/svg/back-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.__student__group-card-loading__b5fd7 .student-group-card {
  // left: 250px;
  // top: 250px;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 75vh;
  z-index: 99;
  // margin-bottom: 15px;
  // -webkit-transform: translateX(-50%) translateY(-50%);
  //     -ms-transform: translateX(-50%) translateY(-50%);
  //         transform: translateX(-50%) translateY(-50%);
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: box-shadow 0.3s;
          transition: box-shadow 0.3s;
}
.__student__group-card-loading__b5fd7 .student-group-card:hover {
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
}
.__student__group-card-loading__b5fd7 .student-group-card a {
  color: inherit;
  text-decoration: none;
}

.__student__group-card-loading__b5fd7 .student-group-card__date {
  background: #08c;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 13px;
  font-weight: bold;

}
.__student__group-card-loading__b5fd7 .student-group-card__date__day {
  display: block;
  font-size: 14px;
  margin-top: 10px;
}
.__student__group-card-loading__b5fd7 .student-group-card__date__month {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}

.__student__group-card-loading__b5fd7 .student-group-card__thumb {
  height: 235px;
  overflow: hidden;
  background-color: #000;
  -webkit-transition: height 0.3s;
          transition: height 0.3s;
}
.__student__group-card-loading__b5fd7 .student-group-card__thumb img {
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
          transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1);
}
.__student__group-card-loading__b5fd7 .student-group-card:hover .student-group-card__thumb img {
opacity: 0.6; 
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}
.__student__group-card-loading__b5fd7 .student-group-card:hover .student-group-card__thumb {
  height: 105px;
}

.__student__group-card-loading__b5fd7 .student-group-card__body {
  position: relative;
  padding: 20px;
  height: calc(100% - 50px);
  // min-height: 185px;
  -webkit-transition: height 0.3s;
          transition: height 0.3s;
}
.__student__group-card-loading__b5fd7 .student-group-card:hover .student-group-card__body {
  height: 315px;
}

.__student__group-card-loading__b5fd7 .student-group-card__category {
  position: absolute;
  top: -25px;
  left: 0;
  height: 25px;
  padding: 0 15px;
  background: #08c;
  color: #fff;
  font-size: 11px;
  line-height: 25px;
}
.__student__group-card-loading__b5fd7 .student-group-card__category a {
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
}


.__student__group-card-loading__b5fd7 .student-group-card__social {
  position: absolute;
  top: -25px;
  right: 0;
  height: 25px;
  padding: 0 15px;
  color: #fff;
  font-size: 11px;
  line-height: 25px;
  background: $tinder-love; // url(/assets/svg/small-heart.svg) no-repeat fixed center; 
  
  a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.__student__group-card-loading__b5fd7 .student-group-card__title {
  padding: 0 0 6px 0;
  margin: 0;
  font-size: 20px;
  color: #000;
  font-weight: bold;
}
// .student-group-card:hover .student-group-card__title {
//   -webkit-animation: titleBlur 0.3s;
//           animation: titleBlur 0.3s;
// }

.__student__group-card-loading__b5fd7 .student-group-card__subtitle {
  margin: 0;
  padding: 0 0 6px 0;
  color: #08c;
  font-size: 16px;
}
// .student-group-card:hover .student-group-card__subtitle {
//   -webkit-animation: subtitleBlur 0.3s;
//           animation: subtitleBlur 0.3s;
// }

.__student__group-card-loading__b5fd7 .student-group-card__description {
  left: 20px;
  right: 20px;
  bottom: 65px;
  margin: 10px !important;
  padding: 10px !important;
  color: #666c74;
  font-size: 14px;
  line-height: 27px;
  opacity: 1;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
          transition: opacity 0.2s, transform 0.2s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transform: translateY(25px);
      -ms-transform: translateY(25px);
          transform: translateY(25px);
}
.__student__group-card-loading__b5fd7 .student-group-card:active .student-group-card__description {
  opacity: 1;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
          transform: translateY(0);
}

.__student__group-card-loading__b5fd7 .student-group-card__footer {
  border-top: 1px solid $vida-primary-dark;
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 14px;
  background-color: $vida-primary-light;
  z-index: 1000;
  font-size: 11px; 
  opacity: 1;
  .md-button:not([disabled]):hover {
    background-color: $vida-primary-light;
  }
}
.__student__group-card-loading__b5fd7 .student-group-card__footer .icon--comment {
  margin-left: 10px;
}

.__student__group-card-loading__b5fd7 .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
}
.__student__group-card-loading__b5fd7 .icon:before {
  display: inline-block;
  text-align: center;
  height: 14px;
  width: 14px;
  margin-top: -2px;
  margin-right: 6px;
}

.__student__group-card-loading__b5fd7 .icon--comment:before {
  content: "\f0e6 ";
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.__student__group-card-loading__b5fd7 .icon--time:before {
  content: "\f017 ";
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

@-webkit-keyframes titleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px transparent;
  }
}

@keyframes titleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px transparent;
  }
}
@-webkit-keyframes subtitleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0);
  }
}
@keyframes subtitleBlur {
  0% {
    opacity: 0.6;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0.6);
  }
  100% {
    opacity: 1;
    text-shadow: 0px 5px 5px rgba(0, 136, 204, 0);
  }
}
