.__notification-bell__cfb9a {
  margin-right: 11px;
  margin-top: 3px;
  position: relative;
  width: 44px;
  svg { overflow: visible; }
  &.notify {
    .bell-hull,
    .clapper {
      fill: white;
    }
    .bell-body { animation: bell 1s ease-in-out; }
    .clapper { animation: clapper 1s ease-in-out; }
    .notification-count {
      display: flex;
      position: absolute;
      animation: notify-count 300ms ease-in-out;
      background: red;
      border-radius: 50%;
      top: 0;
      right: 1px;
      height: 18px;
      width: 18px;
    }
  }
  .notification-count {
    display: none;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 9px;
    font-weight: 600;
    transition: all 300ms ease-in-out;
  }
  .bell-hull,
  .clapper {
    fill: white;
    transition: fill 300ms ease-in-out;
  }
  .bell-body {
    transform-origin: 50% 30%;
  }
  .clapper {
    transform-origin: center center;
  }
}
@keyframes bell {
  0% { transform: rotate(0); }
  20% { transform: rotate(12.5deg); }
  40% { transform: rotate(-12.5deg); }
  60% { transform: rotate(12.5deg); }
  80% { transform: rotate(2deg); }
  85%  { transform: rotate(0); }
  100% { transform: rotate(0); }
}
@keyframes clapper {
  0% { transform: translateX(0);}
  25% { transform: translateX(-3px); }
  50% { transform: translateX(4px); }
  75% { transform: translateX(-2px); }
  100% { transform: translateX(0); }
}
@keyframes notify-count {
  0% { transform: scale(0.1); }
  80% { transform: scale(1.1); }
  100% { transform: scale(1); }
}