

.__student__likert-qa__64ea5 {
  width: 100%;
  color: $vida-dark;
}
.__student__likert-qa__64ea5 .likert-list {
  height: 400px;

  md-list-item {
    height: 80px;
  }
}

// .answer-row {
//   height: 80px;
// }


.__student__likert-qa__64ea5 .strongly-agree-list-item:hover {
  background-color: $strongly-agree;
}
.__student__likert-qa__64ea5 .agree-list-item:hover {
  background-color: $agree;
}
.__student__likert-qa__64ea5 .neutral-list-item:hover {
  background-color: $neutral;
}
.__student__likert-qa__64ea5 .disagree-list-item:hover {
  background-color: $disagree;
}
.__student__likert-qa__64ea5 .strongly-disagree-list-item:hover {
  background-color: $strongly-disagree;
}