//Text
$base__font-size: 16px;

$font-family--montserrat: 'Montserrat', sans-serif;
$font-family--primary : $font-family--montserrat;

$vida-primary-light: $vida-blue;
$vida-primary-dark: $vida-dark-blue; 
$vida-dark: #26343b; 

$primary: "red";
$accent: "grey";
$warn: "deep-orange";

$tinder-back: #f1c40fff;
$tinder-rewind: #f1c40fff;
$tinder-hate: #e04f5fff;
$tinder-love: #1abc9cff;
$tinder-favorite: #3498dbff;
$tinder-skip: #6b3382; 

//Colors
$white: #fff;
$black: $vida-dark;
$gray: $vida-primary-dark;
$blue: $vida-primary-light;
$yellow: $tinder-back;
$green: $tinder-love;

//Color Palette
$palettes: (
  gray-shades: (
    darker: #9FAAB7,
    base: #99A4AE,
    light: #DCDCE3,
    lighter: #BEC1CA,
    lightest: #F2F6F8
  )
);

// Color usages
$main-background-color: #F8FAFA;
$calendar-item-border: #FEFEFE;
$calendar-item-text: $vida-dark;
$calendar-button-color: #E9E8E8;

//Font size variables
$lg-font-size: 17px;
$default-font-size: 14px;
$sm-font-size: 12px;
$xsm-font-size: 10px;

//Font weight variables
$font-weight--bold: 700;
$font-weight--semi-bold: 600;
$font-weight--regular: 400;

//Color Usage
$primary-color: $green;

@function palette($palette, $shade: base){
  @return map-get(map-get($palettes, $palette), $shade);
}
// Retrieved from https://github.com/sshikhrakar/Sass-Starter/
// BEM(Block Element Modifier) Naming Convention
// For Element
// $element  - Just the element name for the parent block (doesn't require the parent Block name)
// @usage    - `.Nav {@include e(item){...}}`
@mixin e($element){
  &__#{$element}{
    @content;
  }
}

// For Modifier
// $modifier  - Just the modifier name for the parent block or element
// @usage     - `.Nav {@include e(item) {@include m(active) {...}}}`
@mixin m($modifier){
  &--#{$modifier}{
    @content;
  }
}

// Media Query
@mixin mq($break){
    @if type-of($break) == 'number' {
      @media(min-width: $break + 'px') {
        @content;
      }
    }
    @else {
      @error "No value could be retrieved for '#{$break}'";
    }
}

.__event-list__93aca *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.__event-list__93aca {
  padding: 0;
  ::-webkit-scrollbar {
    width: 0px;
    display:none;
    background: transparent; /* make scrollbar transparent */
  }

  .events {
    @include e(title) {
      color: palette(gray-shades, lighter);
      display: inline-block;
      font-size: $default-font-size;
      font-weight: $font-weight--semi-bold;
      margin-bottom: 16px;
    }
  
    // @include e(tag) {
    //   background: $blue;
    //   border: 2px solid $calendar-item-border;
    //   -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    //   -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    //   box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    //   border-radius: 20px;
    //   color: $white;
    //   font-size: $xsm-font-size;
    //   font-weight: $font-weight--semi-bold;
    //   width: 60px;
    //   margin-left: 16px;
    //   padding: 5px 2px;
    //   text-align: center;
  
    //   @include m(highlighted) {
    //     background: $yellow;
    //   }
    // }
  
    @include e(item) {
      background: $white;
      border-left: 8px solid $primary-color;
      border-radius: 2px;
      -moz-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
      padding: 15px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 6px;
  
      // @include m(left) {
      //   width: calc(100% - 76px);
      // }
    }
  
    @include e(datetime) {
      font-size: $default-font-size;
      font-weight: $font-weight--semi-bold;
      color: $vida-blue;
      display: block;
      margin-bottom: 4px;
    }
  
    @include e(subtitle) {
      font-size: $sm-font-size;
      font-weight: $font-weight--bold;
      color: palette(gray-shades, darker);
      display: block;
      margin-bottom: 6px;
    }
  
    @include e(name) {
      font-size: $sm-font-size;
      font-weight: $font-weight--bold;
      color: $black;
      display: block;
      margin-bottom: 6px;
    }
  
    @include e(date) {
      font-size: $sm-font-size;
      color: palette(gray-shades, darker);
      display: inline-block;
    }
  }  
}



