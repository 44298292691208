
$vida-red: #de4e43;
$vida-dark-red: #bb4038; 
$vida-blue: #2c97df;
$vida-dark-blue: #20608b; 
$vida-purple: #9c56b8; 
$vida-dark-purple: #6b3382; 

$vida-primary-light: $vida-blue;
$vida-primary-medium: #5C8EAF;
$vida-primary-dark: $vida-dark-blue; 
$vida-dark: #26343b; 
$personality: #f1c40f;
$primary: "red";
$accent: "grey";
$warn: "deep-orange";

$tinder-back: #f1c40fff;
$tinder-rewind: #f1c40fff;
$tinder-hate: #e04f5fff;
$tinder-love: #1abc9cff;
$tinder-favorite: #3498dbff;
$tinder-skip: #6b3382; 

$strongly-disagree: #e74c3c;
$disagree: #e67e22;
$neutral: #f1c40f;
$agree: #2ecc71;
$strongly-agree: #3498db;

.strongly-disagree-answer {
    background: $strongly-disagree url(/assets/svg/radio-red-checked.svg) no-repeat fixed center; 
    position:relative;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.disagree-answer {
    background: $disagree url(/assets/svg/radio-orange-checked.svg) no-repeat fixed center; 
    position:relative;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.neutral-answer {
    background: $neutral url(/assets/svg/radio-yellow-checked.svg) no-repeat fixed center; 
    position:relative;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.agree-answer {
    background: $agree url(/assets/svg/radio-green-checked.svg) no-repeat fixed center; 
    position:relative;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.strongly-agree-answer {
    background: $strongly-agree url(/assets/svg/radio-blue-checked.svg) no-repeat fixed center; 
    position:relative;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.tinder-hate {
  background: $tinder-hate url(/assets/svg/dislike-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.tinder-favorite {
  background: $tinder-favorite url(/assets/svg/star-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.tinder-love {
  background: $tinder-love url(/assets/svg/like-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.tinder-back {
  background: $tinder-back url(/assets/svg/back-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.tinder-rewind {
  background: $tinder-back url(/assets/svg/rewind-big.svg) no-repeat fixed center; 
  position:relative;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.tinder-skip {
    background: $tinder-back url(/assets/svg/skip-big.svg) no-repeat fixed center; 
    position:relative;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
  

@-webkit-keyframes titleBlur {
    0% {
      opacity: 0.6;
      text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
    }
    100% {
      opacity: 1;
      text-shadow: 0px 5px 5px transparent;
    }
  }
  
  @keyframes titleBlur {
    0% {
      opacity: 0.6;
      text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.6);
    }
    100% {
      opacity: 1;
      text-shadow: 0px 5px 5px transparent;
    }
  }
  @-webkit-keyframes subtitleBlur {
    0% {
      opacity: 0.6;
      text-shadow: 0px 5px 5px rgba(0, 136, 204, 0.6);
    }
    100% {
      opacity: 1;
      text-shadow: 0px 5px 5px rgba(0, 136, 204, 0);
    }
  }
  @keyframes subtitleBlur {
    0% {
      opacity: 0.6;
      text-shadow: 0px 5px 5px rgba(0, 136, 204, 0.6);
    }
    100% {
      opacity: 1;
      text-shadow: 0px 5px 5px rgba(0, 136, 204, 0);
    }
  }

@import 'devices';
@import 'ember-paper';

@import 'color-palette-override';
@import "ember-paper-expansion-panel";
@import "ember-paper-stepper";
@import "ember-power-calendar";

.demo-calendar-small {
    @include ember-power-calendar($cell-size: 30px);
}
.demo-calendar-medium {
    @include ember-power-calendar($cell-size: 50px);
}
.demo-calendar-big {
    @include ember-power-calendar($cell-size: 70px);
}

@import 'paper-data-table';
@import "pod-styles";

.top-toolbar {
    // position: absolute;
    // top: 0;
    // right: 0;
    // left: 0;
    // height: 48px;
    color: white !important;
    background: $vida-primary-light !important;
    // padding-left: 0;
  
  
    // min-height: 70px !important;
    // max-height: 70px !important;
    // height: 70px !important;
}
.tse-scrollable {
    min-height: 100vh;

    &.is-40 {
        min-height: 40vh;   
    }
}

md-stepper {
    min-height: 100vh;
}

.center-form {
    min-width: 450px;
}
.sign-in-form {
    padding: 0;
    min-width: 450px;

    .sign-in-form-content {
        padding: 0;
        min-width: 450px;

    }
}

.input-tag{
    border-radius: 54px;
    width: 64%;
    height: 31px;
}

.white-color{
    color: #ffff;
}

.day-one-sol{
    font-size: 25px;
}

.font-small {
    font-size: small;
}

.bg-img{
    height: 100vh;
    width: 100%;
    object-fit: cover;
    position: absolute;
    opacity: 6 ;
}

//   .darken-pseudo {
//     position: relative;
//   }
  
  .bg-img:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .logo-img {
    display: block;
    max-width:409px;
    max-height:51px;
    width: auto;
    height: auto;
  }


.top-msg{
    padding-top: 39px;    
}

.bottom-padding{
    padding-bottom: 11px;
}

.bottom-msg{
    padding-bottom: 27px;
}

.forgot-cap{
    padding-top: 15px;    
}
 

.email-text md-input-container .md-input{
    background-color: #fff;
    border-radius: 22px;
    padding-left: 40px;
    height: 36px !important;
    padding-bottom: 11px;
}
.login-text md-input-container .md-input{
    background-color: #fff;
    border-radius: 22px;
    padding-left: 40px;
    height: 36px !important;
    padding-bottom: 4px;
    padding-top: 6px;
}

.logo{
    padding-top: 55px;
}
.mobile-logo {
    margin-top: 12px;
}
.menu-sidebar {
    background: $vida-primary-dark !important;
    color: white;
    height: 100vh;
}
.dashboardLogo {
    padding-top:40px;
    padding-bottom: 6px;
    background: white;
}

.login-text md-input-container{
    margin: 0px;
    width: 65%;
}

.login-text md-input-container.md-icon-right > md-icon:last-of-type{
    right: 42px;
}

.menuUserImage {
    margin: 18px 0;
    width: 100px; 
}

.email-text md-input-container > md-icon {
    left: 8px;
}

.password-text md-input-container > md-icon{
    padding-left: 6px;
}

.password-text md-input-container > input{
    margin: 0px;
}

.login-text md-input-container.md-icon-left{
    padding-left: 0;
}

.nev-bar .md-toolbar-tools{
    background-color: #fff;
    height: 45px;
}

  

.nev-bar .md-button{
    background-color: #03a9f4;
    color: white !important;
    border-radius: 35px;
    font-size: 11px;
    padding: 0px;
    line-height: 1;
    min-height: 24px;
    min-width: 115px;
}

.nev-bar .md-button.md-fab.md-mini{
    width: 14px;
    height: 25px;
    min-width: 46px;
}

.nev-bar .md-button.md-fab md-icon{
    color: white;
}

.nev-bar md-toolbar.md-tall{
    min-height: 45px;
    height: 0; 
}

.location-data{
    font-size: 12px;
}

.font-thirteen{
    font-size: 13px;
}

.padding-six{
    padding-top: 6px;
}

.padding-eleven{
    padding: 11px;
}

.opacity-hr{
    opacity: 0.2;
    font-size: 0px;
}
.opacity-two{
    opacity: 0.2;
}

.opacity-six{
    opacity: 0.6;
}
.card-col{
    position: relative;
}

.card-col md-card md-card-title{
    padding-top: 3px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px; 
}

.desc{
    line-height: 26px !important;
}

.image-title{
    position: absolute;
    color: #fff;
    bottom: 5px;
    left:22px;
    width:100%;
}

.image-top-right-title {
    position: absolute;
    color: #fff;
    top: 15px;
    right:5px;
    width:100%;
}

.image-top-left-title {
    position: absolute;
    color: #fff;
    top: 15px;
    left:0px;
    width:100%;
}

.event-image{
    width:100%;
    object-fit: cover;
    height: 200px;
}

.event-image-default{
    width:  100%;
    object-fit: scale-down;
    height: 200px;
}

.addEventDialog md-input-container {
    margin: 2px 0px;
}

.file-picker__preview__image.single {
    width: 70px;
    height: 60px;
}

.md-dialog-container .footer{
    justify-content: center;
}
md-card {
    margin: 25px;
}
table > tbody > tr:nth-child(even) {
    background-color: rgba(210, 241, 241, 0.5);
}

table.md-table.md-row-select td.md-cell:first-child,
table.md-table.md-row-select th.md-column:first-child {
    padding: 0 !important;
}
.question-num{
    padding: 12px;
    background-color: #eeeeee;
    border-radius: 49px;
    height: 45px;
    width: 45px;
    font-size: 19px;
}

.label-text{
    padding-bottom: 23px;
}

.input-text{
    height: 26px;
    width: 30%;
}
.dynamic-text{
    height: 26px;
    width: 95%;
}

.question-text{
    height: 33px;
    width: 95%;
}

.zero-height{
    height: 0;
}

.red-color{
 color: red !important;
}

body{
    background-color: #f0f2f4;
    overflow: hidden;
}

.padding-left-eleven {
    padding-left: 11px;
}

.dashbord-chart .md-headline {
    font-size: 15px;
    font-weight: 500;
}
.dashbord {
    margin-right: 25px;
    margin-bottom: 25px;
}
.dashbord md-card {
    margin: 25px 0px 0px 25px;
}

// md-sidenav {
//     width: 236px !important;
// }


*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  *::-webkit-scrollbar-track {
    background: rgba(0,0,0,0);
  }
  
  *::-webkit-scrollbar-thumb {
    background: paper-color($color-grey, '500');
  }
  
  .site-sidenav,
  .site-sidenav.md-locked-open-add-active,
  .site-sidenav.md-locked-open {
    width:     300px;
    min-width: 300px;
    max-width: 300px;
    z-index: 1111;
  }

  md-toolbar {
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }
  .site-sidenav > * {
    width:     300px;
    min-width: 300px;
    max-width: 300px;  }

  md-sidenav.site-sidenav {
    background-color: $vida-blue;
  
    md-toolbar.md-default-theme {
    //   background: linear-gradient(to bottom, paper-color($accent, '600'), paper-color($accent, '400'));
    //   background: linear-gradient(to bottom, paper-var(--accent-600), paper-var(--accent-400));
      border-bottom: 1px solid rgba(#fff, .1);
      color: #fff;
      width:     300px;
      min-width: 300px;
      max-width: 300px;
      height: 56px;
      min-height: 56px;
      max-height: 56px;
  
    }
    .footer-toolbar {
        bottom: 0;
        height: 22px;
        position: absolute;
    }
    md-content {
      background: transparent;
      color: $light-contrast-color;
      md-icon {
        color: inherit;
      }
    }
  
    .menu-item {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
  
      .expand-icon {
        position: relative;
        right: -36px;
        transition: transform 250ms linear;
      }
  
      &.expanded .expand-icon {
        transform: rotate(-180deg);
      }
    }
  
    .submenu-item {
      .md-list-item-inner {
        padding-left: 36px;
      }
    }
  
    .menu-item, .submenu-item {
      min-height: 40px;
  
      &.active, &:hover {
        background-color: rgba(#fff, .1);
      }
  
      .md-list-item-inner {
        min-height: 40px;
  
        md-icon {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }





.webInputType input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box !important;
}

textarea {
    width: 100%;
    height: 90px;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    resize: none;
}


.upload-page {
    margin-left: 60px;
    margin-top: 35px;
}

.upload-page .btn-group {
    margin-top: 13px;    
}
.btn-group{
    padding-top: 7px;
}

.btn-bg-black {
    color: RGB(var(--primary-500-contrast)) !important;
    background-color: #706767 !important;
}

.update-file{
    padding: 35px;
}

.ddl{
    height: 50px !important;
}
.choice-ddl{
    padding-top: 10px;
}

.select-ddl{
    width: 50%;
    height: 35px;
    padding: 0px;
}

.user-choice{
    background-color: beige;
    padding-top: 12px;
}

.top-margin-five {
    margin-top: 5px;
}

.top-margin-ten {
    margin-top: 10px;
}

.date-input {
    height: 58px !important;
    width:100%;
}
.date-input input[type=text] {
    height: 34px;
    border-radius: 0px !important;
}

.image {
    display: block;
    width: 100%;
    height: 90px;
    padding-bottom: 10px;
}

body.ember-application>.ember-view {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.inner-sidenav{
    height: auto;
    min-height: 100%;
    width: auto;
    min-width: 100%;
}

.blue-fg-color {
    color: rgba(49, 140, 224, 0.89) !important;
}

.password-reveal{
    padding-right: 46px;
    height: 25px;
}

.error-mgs{
    color: #d0d5d9;
    margin-left: 20px;
}

.password-text md-input-container > .paper-password-strength {
    display: none;
}

.btn-margin{
    margin-left: -8px;
}

.text p {
    text-align: justify;
    font-weight: 400;
}

.login-bg-img {
    -webkit-background-size: 100%; 
    -moz-background-size: 100%; 
    -o-background-size: 100%; 
    background-size: 100%; 
    -webkit-background-size: cover; 
    -moz-background-size: cover; 
    -o-background-size: cover; 
    background-size: cover;
    // height: auto;
    min-height: 100%;
}

.student_logo_img {
    height: 10% !important;
    margin-bottom: 30px;
}


.show-all-btn{
    padding: 0px;
    background-color: #fff;
    margin: 0px;
    font-weight: 6;
    text-transform: inherit;
}

.padding-left-four{
    padding-left: 4px;
}

.lbl-height{
    height: 34px;
}

.normal-text{
    text-transform: inherit;
}

.edit-btn{
    padding: 0px !important;
    text-align: right;
}

.delete-btn{
    padding: 0px !important;
    text-align: left;
}

.padding-bottm-padding-boeight{
    padding-bottom: 8px;
}

.padding-bottm-two{
    padding-bottom: 2px;
}

button.loginbtn, .signupbtn {
    // padding-right: 45px;
    height: 51px;
    background: none;
    border: none;
    display: block;
    background-size: 35px 35px;
    background-position: 10px center;
    background-repeat: no-repeat;
    // border-radius: 4px;
    // color: white;
    font-family:"Merriweather Sans", sans-serif;
    // font-size: 14px;
    margin-bottom: 15px;
    width: 291px;
    border-bottom: 2px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    // box-shadow: 0 4px 2px -2px gray;
    // text-shadow: rgba(0, 0, 0, .5) -1px -1px 0;
}

button#twitter {
    color: #d0d5d9;
    border-color: #007aa6;
    background-color: #008cbf;
    background-image: url(https://g.twimg.com/Twitter_logo_white.png);
}

.loginInputFields, .signupInputFields {
    md-input-container {
        margin: 1px !important;
    }
}

.loginInputFields {
    padding-top: 100px;
}

.file-picker__preview__image.single,
.user_profile {
    height: 100px;
    width: 100px;
    margin-top: 17px;
    border-radius: 50%;
    object-fit: cover;
}

.signupInputFields {
   padding-top: 20px;
}

.signupLogo {
    padding-top: 20px;
}

.event-input > input,select {
    padding: 6px 20px !important;
    border-radius: 0px !important;
}

.position-relative{
    position: relative;
}

.file-upload-text{
    width: 100%;
    height: 40px;
}
 
table.md-table th.md-column {
    color: black;
}

.upload-file{
    padding-left: 33px;
    font-weight: bold;
}

.date-pic-icon{
    position: absolute;
    display: flex;
    margin: 16px 0 0 89%;
}

.date-input>md-input-container.md-input-focused .md-input, md-input-container .md-input.ng-invalid.ng-dirty, md-input-container.md-input-resized .md-input {
    border-width: 1px 1px 1px 1px;
}
.student-home md-card {
    margin: 8px;
}

.bg-color-white {
    background-color: white;
}

.bg-image-student-login {
    background-image: url('/assets/LoginBG1.png');
}

.user-feedback{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.details{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.2;
    height: 48px;
}

.desc-heading{
    height: 24px;
}

.img-tag:hover{
    opacity: 0.7;
}

.remove {
    padding-top: 7px;	
	padding-right: 7px;
	position: absolute;
    right: -6%;
    bottom: 90%;
    display: block; 
}

.clear-icon{
    color: black;
    font-weight: bold;
}

.remove>md-icon {
    line-height: 23px !important;
    font-size: 30px !important;
}

.full-screen md-card {
    margin: 0px ;
}

.full-screen .back-btn {
    width:auto;
    min-width: auto;
}

.image-div{
    width:100%;
}

.club-input{
    padding: 8px !important;
    border-radius: 0px !important;
}

.club-font-family{
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

.blue-grey {
    background-color: #546e7a !important;
}

.color-white{
    color: #fff;
}

.club-title{
    padding-top: 16px;
    padding-bottom: 13px;
    padding-left: 9px;
    padding-right: 8px;
    text-align: center;
}
 .club-desc{
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 122px;
    font-size: 15px;
    line-height: 1.4;
 }

 .color-burlywood{
    color: burlywood;
 }

 .remove-club{
    position: absolute;
    right: 1%;
    bottom: 86%;
    display: block;
    margin: -6px 7px !important;
    height: 48px !important;
    padding: 6px !important;
 }

 .profile-title{
    font-size: 15px;
    color: #818182;
    font-weight: 600;
    margin-bottom: 16px; 
 }

 .profile>li{
    line-height: 2;
 }

 .profile-list{
    font-size: 15px;
    font-weight: 500;
    line-height: 2; 
 }

 .title{
    padding-top: 0 !important;
    font-size: 14px;
    color: #818182
 }

 .img-responsive>img {
    object-fit: cover;
    border-radius: 108px;
    height: 200px !important;
    width: 200px !important; 
}

.color-red{
    color:red !important;
}
.full-width{
    width:100%;
}

.profile-lbl{
    font-weight: 600;
}

.profile-data{
    font-weight: 600;
    color: #0062cc;
}

.margin-bottom-twenty{
    margin-bottom: 20px;
}


.side-border{
    border-left: 1px solid #f4dcf5;
    border-right: 1px solid #f4dcf5;
    padding-left: 24px;
}

.left-padding-twenty-three{
    padding-left: 23px;
}

.table-wrapper {
    display: block;
    height: 80vh;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    position: relative;
    background: #fff;
}
.pagination-container {
  margin: 20px auto;
  text-align: center;
  bottom: 0;
  padding: 0;
}
.pagination {
  position: relative;
}
.pagination a {
  position: relative;
  display: inline-block;
  color: #2c3e50;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 8px 16px 10px;
}
.pagination a:before {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  top: 0;
  left: 0;
  background-color: #2c3e50;
  border-radius: 24px;
  -webkit-transform: scale(0);
          transform: scale(0);
  transition: all 0.2s;
}
.pagination a:hover,
.pagination a .pagination-active {
  color: #fff;
}
.pagination a:hover:before,
.pagination a .pagination-active:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.pagination .pagination-active {
  color: #fff;
}
.pagination .pagination-active:before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.pagination-newer {
  margin-right: 50px;
}
.pagination-older {
  margin-left: 50px;
}



.login-box {
    // background: #fff;
    padding: 20px;
    max-width: 480px;
    margin: 8vh auto 13vh auto;
    text-align: center;
    letter-spacing: 1px;
    position: relative;
}

.login-box h2 {
    margin: 20px 0 20px;
    padding: 0;
    text-transform: uppercase;
    color: #4688F1;
}

.social-button {
	  background-position: 25px 0px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    height: 50px;
	  line-height: 50px;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 100%;
	  border-radius: 3px;
    margin: 10px auto;
    outline: rgb(255, 255, 255) none 0px;
    padding-left: 20%;
    transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#personality-test {
    background: rgb(255, 255, 255) url('/assets/svg/head-cog.svg') no-repeat scroll 5px 0px / 30px 50px padding-box border-box;
    border: 2px solid $personality;
}

#personality-test:hover {
	  border-color: white;
	  background: $personality url('/assets/svg/head-cog-inverted.svg') no-repeat scroll 5px 0px / 30px 50px padding-box border-box;
	  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

#personality-test span {
	  box-sizing: border-box;
    color: $personality;
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border: 0px none rgb(255, 255, 255);
    outline: rgb(255, 255, 255) none 0px;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#personality-test:hover span {
	  color: #FFF;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#facebook-connect {
    background: rgb(255, 255, 255) url('https://eswarasai.com/projects/social-login/img/facebook.svg') no-repeat scroll 5px 0px / 30px 50px padding-box border-box;
    border: 2px solid rgb(60, 90, 154);
}

#facebook-connect:hover {
	  border-color: white;
	  background: rgb(60, 90, 154) url('https://eswarasai.com/projects/social-login/img/facebook-white.svg') no-repeat scroll 5px 0px / 30px 50px padding-box border-box;
	  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

#facebook-connect span {
	  box-sizing: border-box;
    color: rgb(60, 90, 154);
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border: 0px none rgb(255, 255, 255);
    outline: rgb(255, 255, 255) none 0px;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#facebook-connect:hover span {
	  color: #FFF;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#google-connect {
    background: rgb(255, 255, 255) url('https://eswarasai.com/projects/social-login/img/google-plus.png') no-repeat scroll 5px 0px / 50px 50px padding-box border-box;
    border: 1px solid rgb(220, 74, 61);
}

#google-connect:hover {
	  border-color: rgb(220, 74, 61);
	  background: rgb(220, 74, 61) url('https://eswarasai.com/projects/social-login/img/google-plus-white.png') no-repeat scroll 5px 0px / 50px 50px padding-box border-box;
	  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

#google-connect span {
	  box-sizing: border-box;
    color: rgb(220, 74, 61);
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border: 0px none rgb(220, 74, 61);
    outline: rgb(255, 255, 255) none 0px;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#google-connect:hover span {
	  color: #FFF;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#twitter-connect {
    background: rgb(255, 255, 255) url('https://eswarasai.com/projects/social-login/img/twitter.png') no-repeat scroll 5px 1px / 45px 45px padding-box border-box;
    border: 2px solid rgb(85, 172, 238);
}

#twitter-connect:hover {
	  border-color: white;
	  background: rgb(85, 172, 238) url('https://eswarasai.com/projects/social-login/img/twitter-white.png') no-repeat scroll 5px 1px / 45px 45px padding-box border-box;
	  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

#twitter-connect span {
	  box-sizing: border-box;
    color: rgb(85, 172, 238);
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border: 0px none rgb(220, 74, 61);
    outline: rgb(255, 255, 255) none 0px;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#twitter-connect:hover span {
	  color: #FFF;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#linkedin-connect {
    background: rgb(255, 255, 255) url('https://eswarasai.com/projects/social-login/img/linkedin.svg') no-repeat scroll 13px 0px / 28px 45px padding-box border-box;
    border: 1px solid rgb(0, 119, 181);
}

#linkedin-connect:hover {
	  border-color: rgb(0, 119, 181);
	  background: rgb(0, 119, 181) url('https://eswarasai.com/projects/social-login/img/linkedin-white.svg') no-repeat scroll 13px 0px / 28px 45px padding-box border-box;
	  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

#linkedin-connect span {
	  box-sizing: border-box;
    color: rgb(0, 119, 181);
    cursor: pointer;
    text-align: center;
    text-transform: uppercase;
    border: 0px none rgb(0, 119, 181);
    outline: rgb(255, 255, 255) none 0px;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

#linkedin-connect:hover span {
	  color: #FFF;
	  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}