.__student__question-card__36570 .student-question-card {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 75vh;
  z-index: 99;
  background: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: box-shadow 0.3s;
          transition: box-shadow 0.3s;
}

.__student__question-card__36570 .student-question-card a {
  color: inherit;
  text-decoration: none;
}

.__student__question-card__36570 .question-text {
  size: 32px;
}

.__student__question-card__36570 .student-question-card__date {
  background: #08c;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 13px;
  font-weight: bold;

}
.__student__question-card__36570 .student-question-card__date__day {
  display: block;
  font-size: 14px;
  margin-top: 10px;
}
.__student__question-card__36570 .student-question-card__date__month {
  display: block;
  font-size: 10px;
  text-transform: uppercase;
}

.__student__question-card__36570 .student-question-card__statement-block {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: $vida-primary-dark;
  color: white;
  -webkit-transition: height 0.3s;
          transition: height 0.3s;
}

.__student__question-card__36570 .student-question-card__statement-text {
  max-width: 80%;
}




.__student__question-card__36570 .student-question-card__body {
  position: relative;
  padding: 20px;
  // height: calc(100% - 50px);
  // min-height: 185px;
  -webkit-transition: height 0.3s;
          transition: height 0.3s;
}
// .student-question-card:hover .student-question-card__body {
//   height: 315px;
// }

.__student__question-card__36570 .slide-up-left {
  position: absolute;
  top: -35px;
  left: 0;
  height: 35px;
  padding: 0;

  .student-question-card__category {
    // position: absolute;
    height: 35px;
    right: 0;
    padding: 0 15px;
    color: #fff;
    font-size: 13px;
    line-height: 35px;

    &.is-url {
      background: #08c;
    }
  
    &.is-category {
      background: $tinder-hate; 
    }
  
    &.is-favorite {
      background: $tinder-favorite; 
    }
  
    a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

.__student__question-card__36570 .slide-up-right {
  position: absolute;
  top: -35px;
  right: 0;
  height: 35px;
  padding: 0;

  .student-question-card__social {
    // position: absolute;
    height: 35px;
    right: 0;
    padding: 0 15px;
    color: #fff;
    font-size: 13px;
    line-height: 35px;
  
    &.is-liked {
      background: $tinder-love; 
    }
  
    &.is-disliked {
      background: $tinder-hate; 
    }
  
    &.is-favorite {
      background: $tinder-favorite; 
    }
  
    a {
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}


.__student__question-card__36570 .student-question-card__title {
  margin: 0;
  font-size: 14px;
  color: $vida-primary-dark;
  font-weight: bold;
}

.__student__question-card__36570 .student-question-card__description {
  opacity: 1;
  -webkit-transition: opacity 0.2s, -webkit-transform 0.2s;
          transition: opacity 0.2s, transform 0.2s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transform: translateY(25px);
      -ms-transform: translateY(25px);
          transform: translateY(25px);
}

.__student__question-card__36570 .student-question-card__footer {
  border-top: 1px solid $vida-primary-dark;
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 14px;
  background-color: $vida-primary-light;
  z-index: 1000;
  font-size: 11px; 
  opacity: 1;
  .md-button:not([disabled]):hover {
    background-color: $vida-primary-light;
  }
}
.__student__question-card__36570 .student-question-card__footer .icon--comment {
  margin-left: 10px;
}

.__student__question-card__36570 .icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 2px;
}
.__student__question-card__36570 .icon:before {
  display: inline-block;
  text-align: center;
  height: 14px;
  width: 14px;
  margin-top: -2px;
  margin-right: 6px;
}

.__student__question-card__36570 .icon--comment:before {
  content: "\f0e6 ";
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}

.__student__question-card__36570 .icon--time:before {
  content: "\f017 ";
  display: inline-block;
  font: normal normal normal 16px/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
          transform: translate(0, 0);
}
