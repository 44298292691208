// $vida-red: #de4e43;
// $vida-dark-red: #bb4038; 
// $vida-blue: #2c97df;
// $vida-dark-blue: #20608b; 
// $vida-purple: #9c56b8; 
// $vida-dark-purple: #6b3382; 

// $vida-primary-light: $vida-blue;
// $vida-primary-medium: #5C8EAF;
// $vida-primary-dark: $vida-dark-blue; 
// $vida-dark: #26343b; 

// $primary: "red";
// $accent: "grey";
// $warn: "deep-orange";

// $tinder-back: #f1c40fff;
// $tinder-rewind: #f1c40fff;
// $tinder-hate: #e04f5fff;
// $tinder-love: #1abc9cff;
// $tinder-favorite: #3498dbff;
// $tinder-skip: #6b3382; 


.__student__sidebar-agenda__799bc .ei{
  margin: 18px 0;
  padding:8px;
  cursor: pointer;
  &:hover{
    background-image: linear-gradient(-222deg, $vida-primary-dark, $vida-primary-light);
    box-shadow: 0px 0px 52px -18px rgba(0, 0, 0, 0.75);
    .ei_Dot{
      background-color: #fff;
    }
    .ei_Copy,.ei_Title{
      color:#fff;
    }
  }
}

.__student__sidebar-agenda__799bc .ei_Dot,.__student__sidebar-agenda__799bc .ei_Title{
  display:inline-block;
}

.__student__sidebar-agenda__799bc .ei_Dot{
  border-radius:50%;
  width:10px;
  height: 10px;
  background-color: #ccc;
  box-shadow: 0px 0px 52px -18px rgba(0, 0, 0, 0.75);
}
.__student__sidebar-agenda__799bc .dot_active{
  background-color: $tinder-love;
}

.__student__sidebar-agenda__799bc .ei_Title{
  margin-left:10px;
  color:#363b41;
}

.__student__sidebar-agenda__799bc .ei_Copy{
  font-size:12px;
  margin-left:27px;
}

.__student__sidebar-agenda__799bc .ei-dark{
  background-image: linear-gradient(-222deg, #646464, #454545);
  color:#fff;
  .header_title,.ei_Title,.ce_title{
    color:#fff;
  }
  width: 100%;
  height: 100%;;
  
}