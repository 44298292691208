md-expansion-panel {
  display: block;
  position: relative;
  outline: none;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  background-color: #fff;
  box-shadow: 0 -1px 0 #e5e5e5,
              0 0 2px rgba(0,0,0,.12),
              0 2px 4px rgba(0,0,0,.24);

  // first panel only has top round corners
  &:first-of-type {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  // last panel only has top round corners
  &:last-of-type {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &.md-open {
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 2px;

    &:first-of-type {
      margin-top: 0;
    }
  }

  &:not(.md-no-animation) {
    &.md-open {
      transition: margin-top 0.12s cubic-bezier(0.25, 0.8, 0.25, 1),
                  margin-bottom 0.12s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    &.md-close {
      transition: margin-top 0.08s cubic-bezier(0.25, 0.8, 0.25, 1),
                  margin-bottom 0.08s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }

  // paper-item has 16px and not 24px
  md-list-item.md-no-proxy, md-list-item .md-no-style {
    padding: 0 24px;
  }

  .md-panel-title {
    flex: 1;
    font-size: 16px;
    font-weight: 600;
    min-width: 80px;
    max-width: 184px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
  }

  .md-panel-summary {
    flex: 1;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    white-space: nowrap;
  }

  md-expansion-panel-content {
    display: block;
    padding: 16px 24px;
  }

  md-expansion-panel-footer {
    display: flex;
    min-height: 48px;
    line-height: 48px;
    padding: 10px 8px 10px 24px;
  }
}